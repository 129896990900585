import type { AppProps } from "next/app";
// doing import from here and not from app because nextjs restrictions
import { appWithTranslation } from "next-i18next";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { Router } from "next/router";
import { persistQueryClient } from "react-query/persistQueryClient-experimental";
import { createWebStoragePersistor } from "react-query/createWebStoragePersistor-experimental";
import "react-responsive-modal/styles.css";

import init from "@shared/configs/init";
import "../styles/globals.css";
import { Toaster } from "react-hot-toast";
import Head from 'next/head';

init();

const gAnal = `<!-- Global site tag (gtag.js) - Google Analytics -->
<script async src="https://www.googletagmanager.com/gtag/js?id=UA-205289681-1">
</script>
<script>
  window.dataLayer = window.dataLayer || [];
  function gtag(){dataLayer.push(arguments);}
  gtag('js', new Date());

  gtag('config', 'UA-205289681-1');

  (function(c,l,a,r,i,t,y){
        c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
        t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
        y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
    })(window, document, "clarity", "script", "865brv4em1");

</script>`

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      cacheTime: Infinity,
    },
  },
});

if (process.browser) {
  // somehow it doesn't work rn
  const localStoragePersistor = createWebStoragePersistor({
    storage: window.localStorage,
  });
  persistQueryClient({
    queryClient,
    persistor: localStoragePersistor,
  });
}

Router.events.on("routeChangeComplete", (url) => {
  window.analytics.page(url);
});

function MyApp({ Component, pageProps }: AppProps) {
  return (
    <QueryClientProvider client={queryClient}>
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <div dangerouslySetInnerHTML={{ __html: gAnal }} />
      </Head>
      {/* we need this div othwerwise toaster component apply random styles */}
      <div>
        <Component {...pageProps} />
      </div>
      <Toaster />
      <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
    </QueryClientProvider>
  );
}
export default appWithTranslation(MyApp);
